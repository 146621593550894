import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Looper } from 'components/Looper';
import { ColorLooper } from 'docs/helpers/ColorLooper';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Divider, ColoredDivider, StyledDivider } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Divider" title="Divider" mdxType="PageTitle" />
    <h2 {...{
      "id": "horizontal-divider"
    }}>{`Horizontal divider`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Divider />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Divider,
      ColoredDivider,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Divider mdxType="Divider" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "vertical-divider"
    }}>{`Vertical divider`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <div style={{ height: \'60px\' }}>\n    <Divider orientation=\"vertical\" />\n  </div>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Divider,
      ColoredDivider,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <div style={{
          height: '60px'
        }}>
      <Divider orientation="vertical" mdxType="Divider" />
    </div>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h3 {...{
      "id": "basic-divider"
    }}>{`Basic divider`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Looper\n    list={[undefined, \'quiet\', \'quieter\', \'quietest\']}\n    render={volume => (\n      <div key={volume || \'none\'} style={{ marginBottom: \'12px\' }}>\n        <h4>volume: {volume || \'none\'}</h4>\n        <Divider variant=\"primary\" volume={volume} />\n      </div>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Divider,
      ColoredDivider,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Looper list={[undefined, 'quiet', 'quieter', 'quietest']} render={volume => <div key={volume || 'none'} style={{
          marginBottom: '12px'
        }}>
          <h4>volume: {volume || 'none'}</h4>
          <Divider variant="primary" volume={volume} mdxType="Divider" />
        </div>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contrast-divider"
    }}>{`Contrast divider`}</h3>
    <Playground __position={4} __code={'<ThemeWrapper contrast>\n  <Looper\n    list={[undefined, \'quiet\', \'quieter\', \'quietest\']}\n    render={volume => (\n      <div key={volume || \'none\'} style={{ marginBottom: \'12px\' }}>\n        <h4>volume: {volume || \'none\'}</h4>\n        <Divider contrast volume={volume} />\n      </div>\n    )}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Divider,
      ColoredDivider,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <Looper list={[undefined, 'quiet', 'quieter', 'quietest']} render={volume => <div key={volume || 'none'} style={{
          marginBottom: '12px'
        }}>
          <h4>volume: {volume || 'none'}</h4>
          <Divider contrast volume={volume} mdxType="Divider" />
        </div>} mdxType="Looper" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "styled-divider"
    }}>{`Styled divider`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <StyledDivider dividerColor=\"#393945\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Divider,
      ColoredDivider,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StyledDivider dividerColor="#393945" mdxType="StyledDivider" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "colored-divider"
    }}>{`Colored divider`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper withThemeId>\n  {({ themeId }) => (\n    <React.Fragment>\n      <ColorLooper\n        group=\"primary\"\n        render={({ id }) => (\n          <div key={id} style={{ marginBottom: \'12px\' }}>\n            <h4>Color: {id}</h4>\n            <ColoredDivider colorId={id} themeId={themeId} />\n          </div>\n        )}\n        themeId={themeId}\n      />\n      <ColorLooper\n        group=\"neutral\"\n        render={({ id }) => (\n          <div key={id} style={{ marginBottom: \'12px\' }}>\n            <h4>Color: {id}</h4>\n            <ColoredDivider colorId={id} themeId={themeId} />\n          </div>\n        )}\n        themeId={themeId}\n      />\n    </React.Fragment>\n  )}\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Looper,
      ColorLooper,
      PageTitle,
      ThemeWrapper,
      Divider,
      ColoredDivider,
      StyledDivider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper withThemeId mdxType="ThemeWrapper">
    {({
          themeId
        }) => <React.Fragment>
        <ColorLooper group="primary" render={({
            id
          }) => <div key={id} style={{
            marginBottom: '12px'
          }}>
              <h4>Color: {id}</h4>
              <ColoredDivider colorId={id} themeId={themeId} mdxType="ColoredDivider" />
            </div>} themeId={themeId} mdxType="ColorLooper" />
        <ColorLooper group="neutral" render={({
            id
          }) => <div key={id} style={{
            marginBottom: '12px'
          }}>
              <h4>Color: {id}</h4>
              <ColoredDivider colorId={id} themeId={themeId} mdxType="ColoredDivider" />
            </div>} themeId={themeId} mdxType="ColorLooper" />
      </React.Fragment>}
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "divider"
    }}>{`Divider`}</h3>
    <Props of={Divider} mdxType="Props" />
    <h3 {...{
      "id": "coloreddivider"
    }}>{`ColoredDivider`}</h3>
    <Props of={ColoredDivider} mdxType="Props" />
    <h3 {...{
      "id": "styleddivider"
    }}>{`StyledDivider`}</h3>
    <Props of={StyledDivider} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      